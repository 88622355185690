<template>
	<v-container fluid class="pa-0">
		<v-toolbar elevation="1" :style="stickyTop">
			<v-toolbar-title>Administrators</v-toolbar-title>
			<v-btn icon @click="fetchAdministrators()"
				><v-icon>mdi-refresh</v-icon></v-btn
			>
			<v-spacer />
			<!-- ADD COACHEE -->
			<v-btn fab icon @click="openDialogAdd">
				<v-icon> mdi-plus-circle-outline </v-icon>
			</v-btn>
			<v-dialog width="800" max-width="100vw" v-model="showDialogAdd">
				<AddAdministrator
					:key="dialogKey"
					v-on:closeDialogAdd="showDialogAdd = false"
					v-on:refreshList="fetchAdministrators"
				/>
			</v-dialog>
			<v-spacer />
		</v-toolbar>
		<v-container>
			<v-data-table
				:items="administrators"
				:headers="headers"
				:disable-sort="this.$vuetify.breakpoint.mobile"
				:loading="loading"
				item-key="uid"
				disable-pagination
				hide-default-footer
				no-data-text=""
				no-results-text=""
			>
				<template v-slot:[`item.actions`]="{ item }">
					<v-btn
						v-if="!item.userId"
						icon
						plain
						@click="createAccount(item)"
						>create account</v-btn
					>
					<v-btn
						v-if="item.userId && !item.emailSent"
						icon
						plain
						@click="sendEmail(item)"
						>uitnodigen
					</v-btn>
				</template>
			</v-data-table>
		</v-container>
	</v-container>
</template>

<script>
	import AddAdministrator from "@/components/AddAdministrator";
	import { db, functions } from "@/services/firebase";
	import { httpsCallable } from "firebase/functions";
	import {
		doc,
		collection,
		setDoc,
		getDocs,
		query,
		where,
	} from "firebase/firestore";
	import { mapGetters } from "vuex";
	export default {
		name: "Administrators",
		components: { AddAdministrator },
		data() {
			return {
				loading: false,
				administrators: [],
				tableHeight: null,
				dialogKey: 1,
				searchText: "",
				showDialogAdd: false,
			};
		},
		computed: {
			...mapGetters({
				userInfo: "session/userInfo",
			}),
			headers() {
				return [
					{ text: "Naam", value: "name.lastName" },
					{ text: "Voornaam", value: "name.firstName" },
					{ text: "Email", value: "email" },
					{ text: "Acties", value: "actions" },
				];
			},
			stickyTop() {
				return {
					position: "sticky",
					top: this.$vuetify.application.top + "px",
					zIndex: 1,
				};
			},
		},
		methods: {
			async fetchAdministrators() {
				const cRef = collection(db, "administrators");
				const res = await getDocs(cRef);
				this.administrators = res.docs.map((doc) => {
					return { ...doc.data(), id: doc.id };
				});
			},
			openDialogAdd() {
				this.dialogKey += 1;
				this.showDialogAdd = true;
			},
			async createAccount(_admin) {
				this.loading = true;
				try {
					const params = {
						email: _admin.email,
						displayName: _admin.name.firstName,
						role: "admin",
						id: _admin.id,
					};
					const call = httpsCallable(functions, "createUser");
					const result = await call(params);
					_admin.userId = result.data.userId;
				} catch (e) {
					throw console.error(e);
				}
				this.loading = false;
			},
			async sendEmail(_admin) {
				this.loading = true;
				try {
					const params = {
						to_uid: _admin.userId,
						role: "admin",
						id: _admin.id,
					};
					const call = httpsCallable(functions, "sendEmail");
					await call(params);
					_admin.emailSent = true;
				} catch (e) {
					console.error(e);
				}
				this.loading = false;
			},
		},
		created() {
			this.fetchAdministrators();
		},
	};
</script>

