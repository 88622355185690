<template>
	<v-card>
		<v-card-title>Administator toevoegen</v-card-title>
		<v-card-text>
			<v-text-field
				v-model="administrator.name.firstName"
				label="Voornaam"
			></v-text-field>
			<v-text-field
				v-model="administrator.name.lastName"
				label="Achternaam"
			></v-text-field>
			<v-text-field
				v-model="administrator.email"
				label="Email"
			></v-text-field>
		</v-card-text>
		<v-card-actions>
			<v-btn plain :loading="loading" @click.stop="addAdministrator"
				>Bevestigen</v-btn
			>
			<v-spacer />
			<v-btn plain @click="$emit('closeDialogAdd')">Annuleren</v-btn>
		</v-card-actions>
	</v-card>
</template>
<script>
	import { db } from "@/services/firebase";
	import { collection, addDoc, getDocs } from "firebase/firestore";
	import { mapGetters } from "vuex";
	export default {
		name: "AddAdministrator",
		props: {},
		data() {
			return {
				loading: false,
				administrator: {
					name: {
						firstName: "",
						lastName: "",
					},
					email: "",
				},
			};
		},
		computed: {
			...mapGetters({
				userInfo: "session/userInfo",
			}),
		},
		methods: {
			async addAdministrator() {
				this.loading = true;
				try {
					await addDoc(
						collection(db, "administrators"),
						this.administrator
					);
					this.$emit("refreshList");
					this.$emit("closeDialogAdd");
				} catch (err) {
					console.error(err);
				}
				this.loading = false;
			},
		},
		created() {},
	};
</script>
